import React from "react";


function Footer() {
  return (
    <footer>      
      상호 : (주)지오리서치 <br className="br2"/><span>ㅣ</span> 전화 : 053-710-4790 <br/> 이메일 : go@goresearch.co.kr <br className="br2"/><span>ㅣ</span>Fax : 053-289-3490
    </footer>
  )
}

export default Footer;