import React, { useState } from "react";
import { Route, Switch, Link, useHistory, useParams } from "react-router-dom";

import { Links } from "../../data/BrandLink";

import "../../sass/subPage/brand.scss";

function Brand({ setSubBannerImage, title }) {
  setSubBannerImage("brand");

  const barndName = [
    "Total Solution for Research",
    "Microbial and Plant medium",
    "Solutions For Genetic Technologies",
    "Test kits for plant pathogens and transgenic (GMO) traits",
    "NGS-based target enrichment and synthesis",
    "Biomedical and Lab Equipment",
    "Lab water purification system",
    "Total Plastic ware for Lab",
    "Biomedical and Lab Equipment",
    "Oligonucleotide Synthesis",
    "Biomedical and Lab Equipment",
    "Total Solution for Research",
    "Immune Cell, Recombinant Protein and Monoclonal Antibodies",
    "Fluorescent materials",
    "Laboratory equipment ",
    "Molecular Biology Lab Instrument",
    "Homogenizer",
    "Laboratory equipment",
    "Physics and Chemical Equipment",
    "Life science and clinical diagnostic products",
    "Microarray and NGS Service",
    "Life science research product",
    "Genome Editing Technology",
    "Ultra Water Purification System",
    "Lab Equipment and Plastic ware for Research",
  ]
  const imageList = [];
  for (let i = 0; i < 25; i++) {
    imageList.push(
      <li>
        <a href={Links[i]} target="_blank">
          <img src={`/image/brand${i + 1}.png`} alt="" />
          <p>{barndName[i]}</p>
        </a>
      </li>
    )
  }
  return (
    <div className="container">
      <div className="wrap">
        <div className="title">
          <h2>{title}</h2>
        </div>

        <ul className="brandImage">
          {imageList}
        </ul>
      </div>
    </div>
  )
}

export default Brand;