const Links = [
  "https://www.thermofisher.com/kr/ko/home.html",
  "https://kisanbio.com/",
  "http://www.solgent.com",
  "https://www.agdia.com",
  "https://www.celemics.com/kr",
  "http://www.n-biotek.com",
  "https://rephile.com/ko/%ec%8b%a4%ed%97%98%ec%8b%a4-%ec%a0%95%ec%88%98-%ec%8b%9c%ec%8a%a4%ed%85%9c/",
  "https://www.watsonbiolab.com/index.html",
  "https://biosan.lv",
  "http://www.sfcprobes.com/frt/index.do;jsessionid=FE20C1C8DA7ABE8E8B5F982070D052B7",
  "http://www.genomicbase.com/shop/goods/goods_list.php?&category=017",
  "https://www.mpbio.com/kr",
  "https://nkmax.com/kor",
  "http://bioacts.co.kr/main/main.php",
  "https://www.majorsci.com",
  "http://www.bioer.com.cn/en",
  "http://www.tokken.jp/products/breaker/automill.html",
  "https://www.maestrogen.com/",
  "https://www.mitsuiec.co.jp/",
  "http://en.lifereal.com.cn",
  "https://www.e-biogen.com",
  "http://biomaxinc.com/new/index.php",
  "http://www.toolgen.com",
  "https://www.stakpure.de/",
  "https://www.eppendorf.com/KR-ko",
  "javascript:void(0);",
  "javascript:void(0);",
  "javascript:void(0);",
  "javascript:void(0);",
]

export { Links }